<template>
    <Modal :visible="visible">
        <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs overflow-hidden">
            <div class="mb-8 flex justify-between space-x-3">
                <h3>Shipping Groups</h3>
                <button class="px-2 text-xl text-green" @click="close"><i class="fas fa-times"></i></button>
            </div>
            <div>
                <div class="col-span-1">
                    <label class="block text-xs font-body text-gray-dark mb-2">Location*:</label>
                    <multiselect class="max-w-xs" v-model="location" :options="filteredLocations" :searchable="true"
                        :close-on-select="true" :show-labels="false" :allow-empty="false" label="name" track-by="id"
                        :loading="locations.loading" placeholder="Select an active menu">

                        <template slot="placeholder"><span class="text-gray-400">Select a location</span></template>

                        <template #caret="{ toggle }">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <span class="absolute right-3 top-3 select-none text-green"><i
                                        class="fas fa-chevron-down"></i></span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div v-if="!location" class="mb-96"></div>
            <div v-else class="mb-4"></div>
            <div v-if="location" class="mb-5">
                <p v-if="!prepDateClicked" class="mb-2">Please select a preparation date:</p>
                <p v-if="prepDateClicked" class="mb-2">Please select the shipping date:</p>
                <v-calendar ref="calendar" class="mb-4" trim-weeks :attributes="attributes" :min-date="startDate"
                    @dayclick="handleDayClick" />
                <div class="col-span-1">
                    <label class="block text-xs font-body text-gray-dark mb-2">Menu*:</label>
                    <multiselect class="max-w-xs" v-model="menu" :options="filteredMenus" :searchable="true"
                        :close-on-select="true" :show-labels="false" :allow-empty="false" label="name" track-by="id"
                        :loading="menus.loading" placeholder="Select an active menu">

                        <template slot="placeholder"><span class="text-gray-400">Select a menu</span></template>

                        <template #caret="{ toggle }">
                            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                                <span class="absolute right-3 top-3 select-none text-green"><i
                                        class="fas fa-chevron-down"></i></span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div v-if="isProcessing && !error" class="flex items-center space-x-1 mb-5">
                <Loader class="w-5 h-5" />
            </div>
            <div v-if="error" class="flex items-center space-x-1">
                <span class="text-red">Error: {{ error }}</span>
            </div>
            <div class="flex space-x-2">
                <button class="btn white px-6" @click="close" :disabled="isProcessing">Cancel</button>
                <button class="btn px-6" @click="saveGroup" :disabled="isInvalid || isProcessing">Save</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import { DateTime } from "luxon"
import Modal from '@components/global/modals/Modal'
import Loader from '@components/global/Loader'
import Checkbox from '@components/global/forms/Checkbox'

export default {
    name: "ShippingGroupsModal",

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        Modal,
        Checkbox,
        Loader
    },

    data() {
        return {
            isProcessing: false,
            error: null,
            selectedDays: [],
            prepDateClicked: false,
            prepDate: null,
            shippingDate: null,
            menus: {
                data: [],
                loading: false,
            },
            menu: null,
            locations: {
                data: [],
                loading: false,
            },
            location: null,
            groups: [],
            groupDays: [],
        }
    },

    computed: {
        attributes() {
            let attributes = []

            if (this.selectedDays.length) {
                this.selectedDays.forEach((date) => {
                    attributes.push({
                        key: 'selected-day',
                        dates: { start: date, end: date },
                        highlight: 'green',
                        popover: false
                    })
                })
            }

            this.groupDays = this.filteredGroups.map((group) => {
                return group.date
            })

            if (this.groupDays.length) {
                this.groupDays.forEach((date) => {
                    attributes.push({
                        key: 'group-day',
                        dates: { start: date, end: date },
                        highlight: 'blue',
                        popover: false
                    })
                })
            }

            return attributes
        },
        isInvalid() {
            return !this.prepDate || !this.shippingDate || !this.menu || !this.location
        },
        dates() {
            return this.selectedDays.map(date => DateTime.fromJSDate(date.date).startOf('day').toUTC())
        },
        startDate() {
            return DateTime.now().startOf('day').toJSDate()
        },
        filteredMenus() {
            if (!this.prepDate) {
                return this.menus.data
            }

            const firstOfMonthPrepDate = this.prepDate.startOf('month')

            return this.menus.data.filter(menu => {
                const menuDate = DateTime.fromISO(menu.month_year)
                return menuDate >= firstOfMonthPrepDate
            });
        },
        filteredLocations() {
            return this.locations.data.filter(location => location.shipping === true)
        },
        filteredGroups() {
            if (!this.location) {
                return this.groups
            }
            return this.groups.filter(group => group.location_id === this.location.id)
        }
    },

    methods: {
        close() {
            this.selectedDays = []
            this.$emit('close')
        },
        saveGroup() {
            this.isProcessing = true
            this.error = null
            const data = {
                prepDate: this.prepDate,
                shipDate: this.shippingDate,
                menuId: this.menu.id,
                locationId: this.location.id,
            }
            this.$app.api.shipping.createShippingGroup(data).then(response => {
                this.isProcessing = false

                this.prepDate = null
                this.shippingDate = null
                this.prepDateClicked = false
                this.selectedDays = []

                this.getGroups()
            }).catch(error => {
                console.error(error)
                this.isProcessing = false
                this.error = "An unexpected error occurred. Please try again. If the problem persists, please contact support."
            })
        },
        handleDayClick(date) {
            if (this.prepDate && this.shippingDate) {
                this.prepDate = null
                this.shippingDate = null
                this.prepDateClicked = false
                this.selectedDays = []
            }

            this.prepDateClicked = !this.prepDateClicked
            const selectedDate = DateTime.fromJSDate(date.date).startOf('day')

            if (this.prepDateClicked) {
                this.prepDate = selectedDate
            } else {
                this.shippingDate = selectedDate
            }

            const minDate = DateTime.fromJSDate(this.startDate).startOf('day')

            const isDateInGroupDays = this.groupDays.some(groupDate =>
                DateTime.fromISO(groupDate).startOf('day').toISO() === selectedDate.toISO()
            )

            const isSelectedDateInSelectedDays = this.selectedDays.some(selected =>
                DateTime.fromJSDate(selected.date).startOf('day').toISO() === selectedDate.toISO()
            )

            if (isSelectedDateInSelectedDays) {
                this.selectedDays = this.selectedDays.filter(selected =>
                    DateTime.fromJSDate(selected.date).startOf('day').toISO() !== selectedDate.toISO()
                )
            } else if (selectedDate >= minDate && !isDateInGroupDays) {
                this.selectedDays.push(date)
            }

            return;
        },
        getGroups() {
            let params = {
                relations: 'reservationSize',
                fulfillment_ids: '4',
            }

            return this.$app.api.classes
                .getClasses(params)
                .then((response) => {
                    // this.groupDays = response.data.map((group) => {
                    //     return group.date
                    // })
                    this.groups = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        getMenus() {
            this.menus.loading = true
            return this.$app.api.menus
                .getMenus({
                    future: true,
                    custom: false,
                    month: this.selectedMonth,
                })
                .then((response) => {
                    this.menus.data = response.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => (this.menus.loading = false))
        },
        getLocations() {
            this.locations.loading = true
            return this.$app.api.locations
                .getLocations()
                .then((response) => {
                    this.locations.data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => (this.locations.loading = false))
        },
    },

    watch: {
        visible(newVal) {
            if (newVal) {
                this.getGroups();
            }
        }
    },

    created() {
        if (this.classDate) {
            this.date = DateTime.fromISO(this.classDate)
        }
        this.getGroups()
        this.getMenus()
        this.getLocations()
    },
}
</script>

<style scoped></style>
